// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDRSrtz7zEo33p5C9zPLSYDVS7J_0KGPrc",
  authDomain: "optapi-4aa36.firebaseapp.com",
  projectId: "optapi-4aa36",
  storageBucket: "optapi-4aa36.appspot.com",
  messagingSenderId: "41343756771",
  appId: "1:41343756771:web:41275b98b1e1a2c49958d6",
  measurementId: "G-6YER00CBZ3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };


