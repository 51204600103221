
export const SUBMIT_HEADER = {
    STEP_4: {
        title: "Congratulation!",
        subTitle: "You have completed onboarding, you can start using the Client Portal!"
    }
}

export const WEBSITE = 'www.godocm.com'

export const BUTTON_TEXT = {
    default:'Next',
    launch:'Submit',
    previous: 'Previous',
}

export const STEPS = {
    STEP_1: 'STEP_1',
    STEP_2: 'STEP_2',
    STEP_3: 'STEP_3',
    STEP_4: 'STEP_4',
    SUBMIT:'SUBMIT',
}

const { SUBMIT, ...ALL_STEPS } = STEPS
export const ALL_STEPS_LIST = Object.keys(ALL_STEPS)

export const NEXT_STEP = {
    STEP_1: 'STEP_2',
    STEP_2: 'STEP_3',
    STEP_3: 'STEP_4',
    STEP_4: 'SUBMIT',
}
export const PREVIOUS_STEP = {
    STEP_4: 'STEP_3',
    STEP_3: 'STEP_2',
    STEP_2: 'STEP_1', // Keep this as 'STEP_1' if you want to stay on STEP_1 when going back
};

/*export const PREVIOUS_STEP = Object.fromEntries(
    Object.entries(NEXT_STEP).map(([key, value]) => [value, key])
);*/
/*

import Single from 'template/images/single.png'
import Group from 'template/images/group.png'

export const HEADERS = {
    STEP_1: {
        title: 'Get started',
        subTitle: `Prior to completing this application form, it is essential that you have read and understood the following terms and conditions that will govern your trading account with Godo Ltd (“Godo”), and which together with this application form, comprise our ‘Client Agreement’ with you: Terms & Conditions; Complaint Policy; Conflicts Of Interest Policy; Cookies Policy; Credit Card Disclaimer; Execution Policy; Privacy Policy; Withdrawal & Refund; Risk Warning

        GoDo will use the information you provide in this application form to assess the level of appropriateness of the services and products we provide to you. Please ensure that all information you provide is accurate, and if any future circumstances occur that may alter this information, you are required to notify us in writing.

        You should not proceed with this account application if you are unsure as to the nature of the risks involved in trading using the services provided by Godo. Trading using leverage carries a high level of risk to your capital and it is possible that you lose more than your entire investment.`
    },
    STEP_2: {
        title: "Let's set up a home for all your work",
        subTitle: 'You can always create another workspace later.'
    },
    STEP_3: {
        title: "How are you planning to use Eden?",
        subTitle: "We'll streamline your setup experience accordingly."
    },
}
export const FORM = {
    STEP_1: [{
        name: "fullName",
        label: "Full Name",
        placeholder: "Steve Jobs",
    }, {
        name: "displayName",
        label: "Display Name",
        placeholder: "Steve",
    }],
    STEP_2: [{
        name: "workSpaceName",
        label: "Workspace Name",
        placeholder: "Eden",
    }, {
        name: "workSpaceUrl",
        label: "Workspace Url (Optional)",
        placeholder: "Example.com",
        type: 'website'
    },
    ],
    STEP_3: [{
        type:'groupCheckBox',
        elements:[
            {
                name:'single',
                icon: Single,
                heading: "For myself",
                subHeading: "Write better. Think more clearly. Stay oraganized.",
            }, {
                name:"group",
                icon:Group,
                heading: "With My team",
                subHeading: "Wikis, docs, tasks & projects. all in one place",
            }
        ]
    }]
}*/
