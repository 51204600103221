import "react-step-progress-bar/styles.css";
import { ALL_STEPS_LIST } from "../../containers/Form/constants";
import './style.css'

const getStepLabel = (step) => {
    // Define your step labels here
    const stepLabels = {
        STEP_1: 'Basic Information',
        STEP_2: 'Personal and Financial',
        STEP_3: 'Trading Information',
        STEP_4: 'Declaration',
    };

    return stepLabels[step] || '';
};
export const Progress = ({ currentStep }) => (
    <div className="flex center">
        {ALL_STEPS_LIST.map((step, index) => (
            <div
            key={index}
            className={`steps ${step.toLowerCase()} ${currentStep >= index + 1 ? 'darkStep' : 'lightStep'}`}
        >
            {getStepLabel(step)}
        </div>
        ))}
    </div>
);
